import React from "react"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import { Link } from "gatsby"

const HomeOurMission = ({missionImg, riskImage}) => {
  const missionImage = getImage(missionImg)

  return (
    <section id="our-mission" className="our-mission our-mission-info">
      <div className="our-mission-info-card our-mission-info-card-mission">
        <div>
        <GatsbyImage
          image={missionImage}
          alt="Area Reports image"
          className="our-mission-info-card-img"
        />
        </div>
        <h2>One step ahead of the climate crisis.</h2>
        <p className="our-mission-info-card-text our-mission-info-card-leftp">
          We bring the realities of climate change to your doorstep with a proprietary rating️ and report for any US property.
        </p>
        <Link to="/about-us" className="link-btn">
          About Us →
        </Link>
      </div>
      <div className="our-mission-info-card our-mission-info-card-risk">
        <GatsbyImage
          image={riskImage.childImageSharp.gatsbyImageData}
          alt="Corridor Reports image"
          className="our-mission-info-card-img"
        />
        <h2>What is your climate risk?</h2>
        <p className="our-mission-info-card-text our-mission-info-card-rightp">
          If you own, manage or invest in real estate properties, you should assess the risk of damage caused by extreme weather events, and the potential costs.
        </p>
        <Link to="/solutions" className="link-btn">
          Climate Risk Overview →
        </Link>
      </div>
    </section>
  )
}

export default HomeOurMission
